<template>
  <site-layout>
    <v-main>
      <v-row>
        <v-col cols="12" sm="6">
          <section-start></section-start>
          <div class="text-h4 mb-5">CONTACT</div>
          <titled-paragraph title="Voor informatie">
            <v-row no-gutters>
              <v-col class="shrink" style="min-width: 150px;">De Sociëteit</v-col>
              <v-col class="shrink" style="min-width: 300px;">T +31 (0)181 45 95 15</v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="shrink" style="min-width: 150px;">Voorstraat 31</v-col>
              <v-col class="shrink" style="min-width: 300px;">M <a href="mailto:info@framegroep.nl">info@framegroep.nl</a></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="shrink" style="min-width: 150px;">3231 BE Brielle</v-col>
              <v-col class="shrink" style="min-width: 300px;">W <a href="https://www.framevastgoed.nl" target="_blank">www.framevastgoed.nl</a></v-col>
            </v-row>
          </titled-paragraph>

          <!--<section-start class="mt-10"></section-start>
          <div class="text-h4 mb-5">AANMELDEN NIEUWSBRIEF</div>
          <titled-paragraph>
            Wilt u het laatste nieuws over Frame Vastgoed in uw mailbox ontvangen?<br/>
            Schrijf u dan in voor onze nieuwsbrief.
          </titled-paragraph>
          <v-form>
            <v-text-field label="Naam" single-line hide-details></v-text-field>
            <v-text-field label="E-mail" single-line></v-text-field>
            <v-card flat class="d-flex">
              <v-spacer></v-spacer>
              <v-btn color="black" dark elevation="0" class="rounded-0">Verstuur</v-btn>
            </v-card>
          </v-form>-->
        </v-col>
        <v-col cols="12" sm="6">
          <v-row>
            <v-col>
              <v-responsive aspect-ratio="16/9">
                <google-map :map-config="mapConfig" api-key="AIzaSyD03FGaDMuRCwhk7xkv1dCZFlYArAdglI8" style="max-height: 450px">
                  <template slot-scope="{ google, map }">
                    <google-map-marker
                        :google="google"
                        :map="map"
                        :marker="marker"
                    />
                  </template>

                </google-map>
              </v-responsive>

            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <titled-paragraph title="Routebeschrijving & parkeren">
                Wanneer u ons kantoor komt bezoeken, stel uw routeplanner dan in op het adres Kaatsbaan (ter hoogte van de Moriaanstraat) in Brielle. Op de Kaatsbaan kunt u gratis parkeren en hiervandaan wandelt u door de Moriaanstraat. De Voorstraat bereikt u vervolgens door de eerste straat rechts in te lopen. Ons kantoor op nummer 31 is het tweede pand aan uw rechterhand.
              </titled-paragraph>
            </v-col>
          </v-row>

        </v-col>
      </v-row>
    </v-main>
  </site-layout>
</template>

<script>
import SiteLayout from "../layouts/SiteLayout";
import TitledParagraph from "../components/TitledParagraph";
import SectionStart from "../components/SectionStart";
import GoogleMap from "../components/GoogleMap";
import GoogleMapMarker from "../components/GoogleMapMarker";

export default {
  name: "Contact",
  components: {SectionStart, TitledParagraph, SiteLayout, GoogleMap, GoogleMapMarker},
  data() {
    return {
      mapConfig: { //52.2093662,4.1585425
        center: { lat: 51.9030618,
          lng: 4.1649996 },
        zoom: 15
      },
      marker: {
        position: {
          lat: 51.9030618,
          lng: 4.1649996
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
