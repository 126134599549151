<script>

export default {
  props: {
    google: {
      type: Object,
      required: true
    },
    map: {
      type: Object,
      required: true
    },
    marker: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      mapMarker: null,
      infoWindow: null
    }
  },
  mounted() {
    const { Marker, InfoWindow } = this.google.maps;

    this.mapMarker = new Marker({
      position: this.marker.position,
      marker: this.marker,
      icon: this.marker.icon,
      map: this.map
    });

    if (this.marker.info) {
      this.infoWindow = new InfoWindow({content: this.marker.info});

      this.mapMarker.addListener('click', () => {
        this.infoWindow.open(this.map, this.mapMarker);
      });
    }
  },
  beforeDestroy() {
    if (this.mapMarker)
      this.mapMarker.setMap(null);

  }
};
</script>
